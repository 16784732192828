html {
    scroll-padding-top: 5rem;
}

.nbar {
    position: fixed;
    width: 100%;
    z-index: 1025;
}
body {
    min-height: 100vh;
    font-family: "Roboto", sans-serif;
}
#root {
    height: 100%;
}
.bg-div {
    height: 100%;
    width: 100%;
    pointer-events: none;
    position: fixed;
    z-index: -1;
}
.bg-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}

.background-image-div {
    min-height: 96vh;
    width: 100%;
    pointer-events: "none";
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    background-position-y: bottom;
}
.div-background-content {
    padding-top: 70px;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    min-height: inherit;
    pointer-events: "none";
}
.div-content {
    width: 75%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-basis: content;
    justify-content: center;
    align-items: center;
    color: white;
    pointer-events: "auto";
}
@media only screen and (max-width: 991px) {
    /* Design for mobile */
    [class*="div-content"] {
        width: 95%;
    }
    [class*="hr"] {
        width: 85%;
    }
    [class*="div-form"] {
        width: 100% !important;
    }
    [class*="hr"] {
        margin-top: 10%;
        margin-bottom: 10%;
    }
    
}
.button-div {
    display: flex;
    justify-content: center;
}
.div-button {
    justify-self: center;
    width: 20%;
}
input {
    border: none;
    text-align: center;
    background-color: rgba(114, 112, 112, 0.75);
    color: rgb(255, 255, 255, 1);
}
.div-form {
    width: 50%;
}
.form-button {
    width: 100%;
}
.pfp {
    border-radius: 50%;
    border: 2px solid white;
}
.pfp-dropdown {
    width: fit-content;

    display:flexbox;
}
.dropdown-item:hover {
    background-color: #414950;
}
.dropzone {
    background-color: rgb(10, 69, 97);

    border-radius: 25px;
}
.teamlogo {
    border-radius: 50%;
}
.err {
    border: 2px solid red;
}
.val {
    border: 2px solid green;
}
.err:focus {
    border: 2px solid red;
    outline: none;
}
.val:focus {
    border: 2px solid green;
    outline: none;
}
.errfeedback {
    color: red;
}
table {
    display: inline-table;
    height: 75%;
    width: 100%;
    overflow: auto;
}
h1, h2, h3 {
    color: yellow !important;
}
.activeCard {
    background-color: rgb(0, 163, 41);
}
.hr {
    border-top: 10px;
    border-color: red;
    clear: both;
    display: block;
    width: 100%;
    background-color: white;
    height: 1px;
    opacity: 0.5;

    margin-top: 5%;

    margin-bottom: 5%;
}
.hr-home {
    width: 100% !important
}

a:hover,Link:hover {
    color: yellow !important;

    text-decoration: none;
}
.highlight {
    color: yellow !important
}
.highlightText {
    color: yellow !important
}
.today-text {
    font-weight: bold;
}
.tomorrow-text {
    font-style: italic;
}
.listHighlight li::marker {
    color: #fa7442 !important;
}
.md-18 {
    font-size: 18px;
}
.md-24 {
    font-size: 24px;
}
.md-36 {
    font-size: 36px;
}
.md-48 {
    font-size: 48px;
}
.md-light {
    color: #cccccc;
}
.footer {
    color: white;
}
.footer-container {
    padding-left: 15px;

    padding-right: 15px;
}
.bg-primary {
    background-color: rgb(85, 50, 104) !important;
}
.bg-warning {
    background-color: yellow !important;
}
.btn-warning {
    background-color: yellow !important;
}
.bg-wp {
    background-color: grey;


}
p {
    width: 100%;
}
div:disabled {
    pointer-events: none;
}
.togglediv {
    width: fit-content;
}
.bg-home {
    background-color: rgba(52, 58, 64, 0.9);
    justify-content: start;
    align-items: start;
}
.figure-caption {
    color: white;
}
.players {
    background-color: rgba(52, 58, 64, 0.9);
}
.imgcont {
    width:10%;

    
}
.rosterimg {
    width: min-content;

    min-width: 20px;

    height: 25px;
}
.header-y {
    color:yellow;
}
.nlinktext {
    color: rgb(255, 255, 255, 0.5);
}
.step {
    height: 0px;
    width: 0px;
    visibility: hidden;
    overflow:hidden;
}
.step p {
    color: yellow;
}
.step-active {
    height: 100%;
    width: 100%;
    visibility: visible;
}
.step-img {
    max-width: 500px;
    width: 100%;
}
.step-img-mobile {
    max-width: 300px;

    width: 100%;
}
.custom-control {
    z-index: 0 !important;
}

.compositeCell {
    border-color: rgb(205, 205, 205) !important;

    white-space: nowrap;
}
.compositeModal {
    z-index: 5000;
}
.playerButton {
    color: blue;
    text-decoration: none;
}
.playerButton:hover {
    color: yellow;
}
.tableHeader {
    color:rgb(0, 163, 41) !important
}
.modalTableOverflow {
    max-height: 300px;

    overflow-y: auto;
}
.iconFilled {
    font-variation-settings: 'FILL' 1;
}
.tooltipbg {
    --bs-tooltip-bg: blue !important;
}